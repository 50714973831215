var mena = window.mena || {};

(function ($) {
    'use strict';

    mena.main.init = function () {
        mena.main.stickyHeader();
        //mena.main.animations();
        mena.main.mobileMenu();
        mena.main.svg4everybody();
        mena.main.getNews();
        mena.main.headerSelectMenu();
        mena.main.headerShow();
        mena.main.headerSearch();
        mena.main.mostReadTabs();
        mena.main.audioPlayer();
        mena.main.copyToClipboard();
        mena.main.checkAds();
    };

    mena.main.checkAds = function() {
        let ads = Array.from(document.querySelectorAll('.js-check-date'));

        if(ads.length > 0) {
            let currentDate = new Date().toJSON().slice(0, 10);

            ads.map(function(ad) {
                var from = new Date(ad.dataset.start);
                var to = new Date(ad.dataset.end);
                var check = new Date(currentDate);

                if(check >= from && check < to) {
                    ad.classList.add("content-ad-visible");
                } else {
                    ad.classList.add("content-ad-hidden");
                }
            });
        }
    }

    mena.main.copyToClipboard = function() {
        $('.js-copy-url-to-clipboard').on('click', function(event) {
            event.preventDefault();
            copy_url();
        });

        function copy_url() {
            var dummy = document.createElement("input"),
            text = window.location.href;

            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
        }

    }

    mena.main.audioPlayer = function() {
        if($('.js-audio-source').length > 0) {

            $('.js-audio-source').each(function() {
                let element = $(this);
                let source = $(this)[0];
                setInterval(function() {
                    var currentTime = formatTime(source.currentTime);
                    $(element).siblings('.js-current-time').text(currentTime);
                }, 1000);
            })


            $('.audio__container').on('click', function() {
                var audioSource = $(this).find('audio');

                if(!$(this).hasClass('playing')) {
                    $(audioSource)[0].play();
                } else {
                    $(audioSource)[0].pause();
                }

                $(this).toggleClass('playing');
            });

            function formatTime(seconds) {
                var minutes = Math.floor(seconds / 60);
                minutes = (minutes >= 10) ? minutes : "0" + minutes;
                var seconds = Math.floor(seconds % 60);
                seconds = (seconds >= 10) ? seconds : "0" + seconds;
                return minutes + ":" + seconds;
            }
        }
    }

    mena.main.mostReadTabs = function() {
        if($('.most-read').length > 0) {

            $('.most-read__tabs-switcher > div').first().addClass('active');
            $('.most-read__tabs-container > div').first().addClass('active');

            $('.most-read__tabs-switch').on('click', function() {
                if(!$(this).hasClass('active')) {
                    $('.most-read__tabs-switch.active').removeClass('active');
                    $('.most-read__tab.active').removeClass('active');
                    $(this).addClass('active');

                    $('.most-read__tab[data-tab="'+ $(this).data('tab') +'"]').addClass('active');
                }
            });
        }
    }

    mena.main.headerSearch = function() {
        $('.header__search-icon').on('click', function() {
            $('.header .search-form').addClass('active');
            $('.header .search-form input[type="text"]').focus();
        });

        $('.search-form__close').on('click', function() {
            $('.header .search-form').removeClass('active');
        });
    }

    mena.main.headerSelectMenu = function() {
        $('.header__search-select-toggle').on('click', function() {
            $(this).parent().toggleClass('active');
        });
    }

    mena.main.headerShow = function() {
        var response;

        function get_current_time() {
            let currentTime, currentTimeHours, currentTimeMinutes;
            let timeInZagreb = fetch('https://worldtimeapi.org/api/timezone/Europe/Zagreb');
            timeInZagreb.then(function(response) {
                return response.json();
            }).then(function(data) {
                currentTime = data.datetime;
                // Get hours
                currentTimeHours = currentTime.slice(11, 13);
                // Get minutes
                currentTimeMinutes = currentTime.slice(14, 16);

                currentTime = Number(String(currentTimeHours + '' +  currentTimeMinutes));

                // Set current time ibn local storage, update if exists
                localStorage.setItem('currentTime', currentTime);
            });
        }

        //Run the fuction for initial time
        get_current_time();

        // Refresh every minute
        setInterval(function() {
            get_current_time();
        }, 60000);

        setInterval(function() {
            var shows = JSON.parse($('#header-shows').val());
            var activeShow = shows[checkTime(shows)];

            if(activeShow) {
                $('.js-show-name').text(activeShow.name);
                $('.js-show-time').text(activeShow.time_from + '-' + activeShow.time_to);
                $('.header__live-show-image').attr('src', activeShow.thumbnail);
                $('.header__live-show').addClass('active');
            } else {
                $('.js-show-name').text('-');
                $('.js-show-time').text(': -');
                $('.header__live-show-image').attr('src', $('.header__live-show-image').data('placeholder'));
            }
        }, 1000);

        function checkTime(shows) {

            // Get current time from local storage
            var currentTime = localStorage.getItem('currentTime');


            shows.forEach(function(show, index) {
                var showTimeFrom = Number(String(show.time_from).replace(':', ''));
                var showTimeTo = Number(String(show.time_to).replace(':', ''));


                // Check if current time is between show time
                if(currentTime >= showTimeFrom && currentTime <= showTimeTo) {
                    response = index;
                }
            });
            return response;
        }
    }

    mena.main.getNews = function () {
        let pageNumber;
        listenPagination();

        function listenPagination() {
            $(".all-news .page-numbers:not(.dots)").on("click", function (ev) {
                ev.preventDefault();
                if (!$(this).hasClass("current")) {
                    pageNumber = $(this).text();

                    if ($(this).hasClass("next")) {
                        pageNumber =
                            parseInt($(this).siblings(".current").text()) + 1;
                    }

                    if ($(this).hasClass("prev")) {
                        pageNumber =
                            parseInt($(this).siblings(".current").text()) - 1;
                    }
                    getNews(pageNumber);
                }
            });
        }

        function getNews(paginationNumber) {
            $.ajax({
                type: "POST",
                url: menaObject.ajaxurl,
                dataType: "html", // add data type
                data: {
                    action: "get_blog_posts",
                    paginationNumber: paginationNumber,
                },

                beforeSend: function () {
                    $(".all-news__posts").addClass("loading");
                },

                success: function (response) {
                    $(".pagination").remove();
                    document
                        .querySelector(".all-news__posts")
                        .scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                            inline: "nearest",
                        });
                    $(".all-news__posts").html(response);
                    $(".all-news__posts").removeClass("loading");
                    var pagination = $(".pagination").detach();
                    $(".pagination-holder").append(pagination);
                    listenPagination();
                },
            });
        }
    };

    mena.main.animations = function () {
        gsap.registerPlugin("scrollTrigger");
        ScrollTrigger.batch("[data-animation='fade-up']", {
            interval: 0.1, // time window (in seconds) for batching to occur.
            batchMax: 10,
            onEnter: (batch) =>
                gsap.to(batch, {
                    opacity: 1,
                    y: 0,
                    stagger: { each: 0.1 },
                    ease: "power2.inOut",
                    overwrite: true,
                    duration: 0.5,
                }),
            start: "top 80%",
            end: "top top",
        });
    }

    mena.main.svg4everybody = function() {
        svg4everybody();
    }

    mena.main.mailchimp = function () {
        if ($(".js-mailchimp").length > 0) {

            loadjs(menaObject.assets + "/js/mailchimp-validate.js", function () {
                window.fnames = new Array();
                window.ftypes = new Array();
                fnames[0] = "EMAIL";
                ftypes[0] = "email";
            });
        }
    };

    mena.main.debounce = function(func, wait, immediate) {

        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };

    };

    mena.main.stickyHeader = function() {

        var stickyHeader = function() {
            var stickyHeader = $( '.sticky-header' );

            if ( $( window ).scrollTop() > 1 ) {
                stickyHeader.addClass( 'sticky' );
            } else {
                stickyHeader.removeClass( 'sticky' );
            }
        }

        window.addEventListener('load', stickyHeader);
        window.addEventListener('scroll', mena.main.debounce( stickyHeader, 150 ));
        window.addEventListener('resize', mena.main.debounce( stickyHeader, 150 ));

    };

    mena.main.mobileMenu = function() {

        var respMenu = $( '.resp-menu' );
        var button   = $( '.js-menu-toggle' );
        var body     = $( 'body' );

        button.click(function(e) {
            body.toggleClass('menu-open');
            button.toggleClass('menu-toggle--active');
        });

        /* Adding submenu arrows to the responsive navigation */
        respMenu.find('.sub-menu').each(function() {
            $(this).parent().append('<a class="submenu-button" href="javascript:void(0)"><svg class="arrow-down" width="15px" height="8px"><polygon fill="#fff" points="15.002,0 7.501,7.501 0,0 "></polygon></svg></a>');
        });

        $('.submenu-button').click(function() {
            $('.sub-menu').not($(this).siblings()).slideUp(300);
            $(this).prev('.sub-menu').slideToggle(100);
            $(this).toggleClass('rotate-arrow');
        });

    };

return mena.main.init();

}($));